import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hotel_data:[],
    
    loader:false,
    feedback_dailog:false
  },
  mutations: {
    SET_DATA(state,all_data){
      state.hotel_data = all_data
    },
 
    SET_LOADER(state,boolean){ 
      state.loader = boolean
    },
    SET_FEEDBACK_DAILOG(state,boolean){
       state.feedback_dailog = boolean
    }
  },
  getters:{
    main_menu_list: (state) => state.hotel_data.main_menu_list,
    sub_menu_list:(state) => state.hotel_data.sub_menu_list,
    hotel_info:(state) =>state.hotel_data.hotel_name,
    loader:(state) =>state.loader,
    feedback_dailog:(state) =>state.feedback_dailog

  },
  actions: {
   async getData({commit},payload){
    commit("SET_LOADER",true)
    await  axios.post('https://bharplate.com/panel/public/api/get_menu',payload)
      .then(response =>{
        commit("SET_LOADER",false)
         let all_data = response.data
         commit('SET_DATA',all_data)
         document.title=all_data.hotel_name[0].name
         })
         .catch(error =>{
              commit("SET_LOADER",false)
            console.log(error)
      })    
    
    },
    
    insertFeedback({commit},payload){
      console.log(payload)
      let branch_id= localStorage.getItem('hotel_branch_id') 
      payload.branch_id =branch_id
      commit("SET_LOADER",true)
      axios.post('https://bharplate.com/panel/public/api/save_feedback',payload)
      .then(response=>{
        console.log(response.status)
        if(response.status === 200 || response.status === 201){            
          commit("SET_LOADER",false)
       
        commit("SET_FEEDBACK_DAILOG",true)
      }else{   
        commit("SET_LOADER",true)
      }})
      .catch(error =>{console.log(error)})
    },
    setFeedbackDialog({commit},boolean){
      commit("SET_FEEDBACK_DAILOG",boolean)
    },
   
  },
  modules: {
  }
})
