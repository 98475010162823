import Vue from 'vue'
import VueRouter from 'vue-router'

// Containers

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/tabs',
    component: () => import('@/containers/Index.vue'),
    children: [
      {
        path: '/tabs',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue')
      },
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('@/views/Feedback.vue')
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
